import React from "react"
import SectionHeaderWithBadge from "src/components/SectionHeaderWithBadge"
import styled from "styled-components"
import SectionMainFeature from "src/components/SectionMainFeature"
import earn_end_graphic from "src/images/earn_end_graphic.png"
import growSubFeaturesData from "src/data/growSubFeaturesData"
import SectionSubFeaturesWithCard from "src/components/SectionSubFeaturesWithCard"
import { rem } from "src/utils/styling"
import { useEnvironment } from "src/utils/environment"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${rem(60)}rem 0 ${rem(80)}rem 0;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    margin: 0;
  }
`

const SubFeatures = styled.div`
  display: flex;
  margin: ${rem(60)}rem 0 ${rem(80)}rem 0;
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: ${rem(60)}rem 0 ${rem(40)}rem 0;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    margin: ${rem(60)}rem 0 ${rem(40)}rem 0;
  }
`

const VideoPlayer = styled.iframe`
  height: ${rem(370)}rem;
  width: ${rem(658.29)}rem;
  border-radius: ${rem(12)}rem;
  @media screen and (max-width: 500px) {
    height: ${rem(167)}rem;
    width: ${rem(297)}rem;
  }
  @media screen and (max-width: 1100px) {
    height: ${rem(274)}rem;
    width: ${rem(487)}rem;
  }
`

const Image = styled.img`
  @media screen and (max-width: 500px) {
    margin-bottom: 50px;
  }
`

const GrowSection = () => {
  const environment = useEnvironment()

  return (
    <Container>
      <SectionHeaderWithBadge
        backgroundColor="#FFE4D0"
        color="#FF6A00"
        body="Learn from developers that have traversed your path before you."
        heading="Polish Your Craft"
        badgeText="grow"
      />
      <SectionMainFeature
        rightData={
          <VideoPlayer
            className="eventLayoutIframeContainerIframe"
            src="https://www.youtube.com/embed/XdsITp-eilo"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        }
        headline="Engage with World Class Talent"
        body="Interested in a particular topic? Attend our Engineering Mastermind Series to learn from some of the best in the business. Need mentorship? Be assigned a mentor at a band or two higher than yours and build a personalized growth plan."
        ctaData={{
          color: "#FF6A00",
          bgColor: "#FFE4D0",
          onClickUrl:
            environment === "staging"
              ? "https://s.archimydes.dev/fourthact/events"
              : "https://archimydes.dev/fourthact/events",
          buttonText: "View events",
        }}
      />
      <SubFeatures>
        {growSubFeaturesData.map(el => (
          <SectionSubFeaturesWithCard
            body={el.body}
            header={el.header}
            icon={el.icon}
            key={el.header}
          />
        ))}
      </SubFeatures>
      <Image src={earn_end_graphic} alt="" />
    </Container>
  )
}

export default GrowSection
