import React from "react"
import styled from "styled-components"
import {
  devBandingDataColumnOne,
  devBandingDataColumnTwo,
} from "src/data/devBandingData"
import { DEVICE_TYPE_DESKTOP, useDeviceType } from "src/utils/deviceType"

const ContainerDevBandingCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  width: 260px;
  height: 175px;
  background: ${props => props.backgroundColor};
  box-shadow: 6px 6px 0 ${props => props.borderColor};
  border-radius: 8px;
  position: relative;
  margin: 0 50px;
  @media screen and (max-width: 500px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 1100px) {
    margin-bottom: 60px;
  }
`

const ContainerDevBanding = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

const ContainerDevBandingColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const DevBandTitle = styled.h5`
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 34px;
  color: #000000;
`

const DevBandBody = styled.h5`
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
  opacity: 0.7;
`

const DevBandBadge = styled.img`
  position: absolute;
  top: ${props => (props.guru ? "-65px" : "-40px")};
  right: ${props => (props.guru ? "-60PX" : "-40px")};
  width: ${props => (props.guru ? "125px" : "100px")};
  height: ${props => (props.guru ? "125px" : "100px")};

  @media screen and (max-width: 768px) {
    top: ${props => (props.guru ? "-55px" : "-30px")};
    right: ${props => (props.guru ? "-50px" : "-40px")};
    width: ${props => (props.guru ? "90px" : "70px")};
    height: ${props => (props.guru ? "90px" : "70px")};
  }
`

const DevBandingCard = ({
  title,
  body,
  badge,
  backgroundColor,
  borderColor,
}) => {
  return (
    <ContainerDevBandingCard
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <DevBandBadge guru={title === "Guru" ? true : false} src={badge} alt="" />
      <DevBandTitle>{title}</DevBandTitle>
      <DevBandBody>{body}</DevBandBody>
    </ContainerDevBandingCard>
  )
}

const Title = styled.h4`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 60px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
    padding: 0 10px;
  }
`

const DevBanding = () => {
  const deviceType = useDeviceType()

  return (
    <ContainerDevBanding>
      <Title>A Banding System to Maximize your Developer Potential</Title>
      {deviceType === DEVICE_TYPE_DESKTOP ? (
        <>
          <ContainerDevBandingColumn>
            {devBandingDataColumnOne.map(el => (
              <DevBandingCard
                key={el.title}
                badge={el.badge}
                body={el.body}
                title={el.title}
                backgroundColor={el.backgroundColor}
                borderColor={el.borderColor}
              />
            ))}
          </ContainerDevBandingColumn>
          <ContainerDevBandingColumn>
            {devBandingDataColumnTwo.map(el => (
              <DevBandingCard
                key={el.title}
                badge={el.badge}
                body={el.body}
                title={el.title}
                backgroundColor={el.backgroundColor}
                borderColor={el.borderColor}
              />
            ))}
          </ContainerDevBandingColumn>
        </>
      ) : (
        <>
          {[...devBandingDataColumnOne, ...devBandingDataColumnTwo]
            // .reverse()
            .map(el => (
              <DevBandingCard
                key={el.title}
                badge={el.badge}
                body={el.body}
                title={el.title}
                backgroundColor={el.backgroundColor}
                borderColor={el.borderColor}
              />
            ))}
        </>
      )}
    </ContainerDevBanding>
  )
}

export default DevBanding
