import React from "react"
import collaboration_icon from "src/images/icons/collaboration_icon.svg"
import excellence_icon from "src/images/icons/excellence_icon.svg"
import global_icon from "src/images/icons/global_icon.svg"

const corePrinciplesData = [
  {
    text: (
      <>
        Decouple income from <b>Geography</b>
      </>
    ),
    image: global_icon,
    textColor: "#000",
  },
  {
    text: (
      <>
        Nurture the <b>Excellence</b> of our craft
      </>
    ),
    image: excellence_icon,
    textColor: "#000",
  },
  {
    text: (
      <>
        Foster <b>Belonging</b> in an ever changing world
      </>
    ),
    image: collaboration_icon,
    textColor: "#000",
  },
]

export default corePrinciplesData
