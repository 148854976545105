import React from "react"
import { darkTheme } from "src/utils/themes"
import Container from "src/components/Container"
import { NewLandingPageHeader } from "src/components/Header"
import SEO from "src/components/seo"
import { Link } from "gatsby"
import { LightFooter } from "src/components/Footer"
import styled, { ThemeProvider } from "styled-components"
import CorePrinciples from "src/components/CorePrinciples"
import { rem } from "src/utils/styling"
import DevBanding from "src/components/DevBanding"
import EarnSection from "src/components/EarnSection"
import GrowSection from "src/components/GrowSection"
import EngageSection from "src/components/EngageSection"
import { HiChevronRight } from "react-icons/hi"
import { PageButton } from "src/pages/code"
import { PageSection } from "src/components/PageSection"
import DeveloperTestimonials from "src/components/DeveloperTestimonials"
import corePrinciplesData from "src/data/corePrinciplesData"

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1100px) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
`

const ContainerForFirstFold = styled(Content)`
  justify-content: space-between;
`

const H1 = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1px;
  color: #000000;
  b {
    color: #00b8c5;
    font-weight: 900;
  }
  @media screen and (max-width: 500px) {
    text-align: center;
  }
  @media screen and (min-width: 1100px) {
    text-align: center;
  }
  @media screen and (max-width: 1100px) {
    text-align: center;
  }
`

const NewLandingBanner = styled.div`
  padding: 7rem 12rem 1rem 12rem;
  width: 100%;
  @media screen and (max-width: 1300px) {
    padding: 5rem 10rem 6rem 10rem;
  }
  @media screen and (max-width: 1100px) {
    padding: 10rem 2rem 2rem 2rem;
    margin-top: 0;
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 1.5rem 2rem;
  }
`

const LandingBannerContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1100px) {
    margin-top: ${rem(50)}rem;
  }
  @media screen and (max-width: 550px) {
    margin-top: ${rem(30)}rem;
  }
`
const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(100)}rem ${rem(140)}rem;
  background: linear-gradient(283.7deg, #03162c 28.07%, #4d5357 185.43%);
  border-radius: 20px;
  width: 90%;
  margin: 0 auto -${rem(180)}rem;
  z-index: 9999;
  @media screen and (max-width: 500px) {
    padding: 60px;
  }
  @media screen and (min-width: 1100px) {
    width: 894px;
  }
`

const CTAContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`

const LandingPageSection = styled(PageSection)`
  padding: ${rem(50)}rem 0;
  position: relative;
  background-color: ${props => (props.color ? props.color : "transparent")};
  @media screen and (min-width: 1100px) {
    padding: ${rem(10)}rem 0;
  }
  @media screen and (max-width: 1100px) {
    padding: ${rem(5)}rem 0;
  }
  @media screen and (max-width: 550px) {
    padding: ${rem(20)}rem 0;
  }
`

const IndexPage = ({ location }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Container />
      <NewLandingPageHeader />
      <NewLandingBanner>
        <LandingBannerContent>
          <ContainerForFirstFold>
            <SEO
              title="Archimydes: A Global AI & Software Engineering Guild"
              description="The Guild is focused on enabling developers to Engage, Grow, and Earn."
              location={location}
            />
            <H1>
              <b>Archimydes</b>: A Global AI & Software Engineering Guild
            </H1>
          </ContainerForFirstFold>
        </LandingBannerContent>
      </NewLandingBanner>
      <CorePrinciples data={corePrinciplesData} />
      <DevBanding />
      <EngageSection />
      <GrowSection />
      <EarnSection />
      <DeveloperTestimonials />
      <LandingPageSection>
        <CTAContainer>
          <CTAContainerInner>
            <SectionTitle color={"#fff"} style={{ paddingBottom: 20 }}>
              Interested in joining the Guild?
            </SectionTitle>
            <Link to="/devjoin">
              <PageButton type="solid">
                Join Guild Waitlist
                <HiChevronRight size={22} />
              </PageButton>
            </Link>
          </CTAContainerInner>
        </CTAContainer>
      </LandingPageSection>
      <LightFooter paddingTop={280} />
    </ThemeProvider>
  )
}

export default IndexPage
