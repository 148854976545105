import React from "react"
import styled from "styled-components"
import { rem } from "src/utils/styling"
import { DEVICE_TYPE_DESKTOP, useDeviceType } from "src/utils/deviceType"
import { MobileOnlyCarousel } from "src/components/Carousel"

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${rem(20)}rem ${rem(10)}rem;
  height: ${rem(220)}rem;
  @media screen and (max-width: 500px) {
    height: fit-content;
    width: fit-content;
  }
  @media screen and (min-width: 1100px) {
    margin: 0 ${rem(40)}rem;
    max-width: ${rem(220)}rem;
  }
`

const Image = styled.img`
  margin: ${rem(20)}rem;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const Text = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #969696;
  b {
    font-weight: 900;
    color: ${props => (props.textColor ? props.textColor : "black")};
  }
  @media screen and (max-width: 500px) {
    width: 70%;
  }
`

const ItemContainerForMobile = styled.div`
  display: flex;
  justify-content: center;
`

const CorePrincipleItem = ({ image, text, textColor }) => {
  return (
    <ItemContainerForMobile>
      <ItemContainer>
        <Image src={image} alt="icon" />
        <Text textColor={textColor}>{text}</Text>
      </ItemContainer>
    </ItemContainerForMobile>
  )
}

const CorePrinciples = ({ data }) => {
  return (
    <Container>
      {data.map(item => (
        <CorePrincipleItem
          key={item.text}
          image={item.image}
          text={item.text}
          textColor={item.textColor}
        />
      ))}
    </Container>
  )
}

export default CorePrinciples
