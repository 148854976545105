import React from "react"
import styled from "styled-components"
import { rem } from "src/utils/styling"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(70)}rem;

  @media screen and (max-width: 768px) {
    margin-bottom: ${rem(40)}rem;
  }
`

const Heading = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: ${rem(38)}rem;
  line-height: ${rem(49)}rem;
  text-align: center;
  color: #000000;
  margin-bottom: ${rem(10)}rem;
  text-transform: capitalize;

  @media screen and (max-width: 1100px) {
    font-size: ${rem(22)}rem;
    line-height: ${rem(34)}rem;
  }
`

const Body = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.bodySize ? props.bodySize : `${rem(18)}rem`)};
  line-height: ${rem(36)}rem;
  text-align: center;
  color: #000000;
  opacity: 0.7;
  width: ${props => (props.bodyWidth ? props.bodyWidth : "70%")};
  @media screen and (max-width: 1100px) {
    font-size: ${rem(14)}rem;
    line-height: ${rem(24)}rem;
    width: 90%;
  }
`

const Badge = styled.div`
  background: ${props => props.backgroundColor};
  padding: ${rem(10)}rem ${rem(30)}rem;
  border-radius: ${rem(6)}rem;
  margin-bottom: ${props =>
    props.badgeMarginBottom ? props.badgeMarginBottom : `${rem(10)}rem`};
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: ${rem(13)}rem;
  line-height: ${rem(16)}rem;
  letter-spacing: ${rem(2)}rem;
  text-transform: uppercase;
  color: ${props => props.color};

  @media screen and (max-width: 768px) {
    font-size: ${rem(12)}rem;
    line-height: ${rem(14)}rem;
  }
`

const SectionHeaderWithBadge = ({
  badgeText,
  heading,
  body,
  backgroundColor,
  color,
  bodyWidth,
  bodySize,
  badgeMarginBottom,
}) => {
  return (
    <Container>
      <Badge
        badgeMarginBottom={badgeMarginBottom}
        backgroundColor={backgroundColor}
        color={color}
      >
        {badgeText}
      </Badge>
      {heading && <Heading>{heading}</Heading>}
      {body && (
        <Body bodyWidth={bodyWidth} bodySize={bodySize}>
          {body}
        </Body>
      )}
    </Container>
  )
}

export default SectionHeaderWithBadge
