import React from "react"
import styled, { css } from "styled-components"
import { rem } from "src/utils/styling"
import NewCustomButton from "src/components/NewCustomButton"

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1100px) {
    width: 90%;
  }
`

const Left = styled.div`
  ${props =>
    props.isFullWidth &&
    css`
      width: 25%;
      margin-right: ${rem(20)}rem;
    `}
  width: 20%;
  margin-right: ${rem(80)}rem;
  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 500px) {
    ${props =>
      props.isFullWidth &&
      css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    width: 100%;
    margin-right: 0;
    padding: 0 40px;
    margin-bottom: 20px;
  }
`

const Right = styled.div`
  @media screen and (max-width: 1100px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: auto;
    margin-bottom: 80px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const MiniHeadline = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${rem(12)}rem;
  line-height: ${rem(14)}rem;
  letter-spacing: ${rem(2)}rem;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: ${rem(10)}rem;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`

const Headline = styled.p`
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: ${rem(26)}rem;
  line-height: ${rem(34)}rem;
  color: #000000;
  margin-bottom: ${rem(10)}rem;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`

const Body = styled.p`
  font-family: "Nunito", monospace;
  font-style: normal;
  font-weight: normal;
  font-size: ${rem(17)}rem;
  line-height: ${rem(34)}rem;
  color: #000000;
  margin-bottom: 15px;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`

const SectionMainFeature = ({
  miniHeadline,
  headline,
  body,
  children,
  rightData,
  ctaData,
}) => {
  return (
    <Container>
      <Left isFullWidth={children && true}>
        {children ? (
          children
        ) : (
          <>
            {miniHeadline && <MiniHeadline>{miniHeadline}</MiniHeadline>}
            <Headline>{headline}</Headline>
            <Body>{body}</Body>
            <NewCustomButton
              color={ctaData && ctaData.color}
              bgColor={ctaData && ctaData.bgColor}
              onClickUrl={ctaData && ctaData.onClickUrl}
            >
              {ctaData && ctaData.buttonText}
            </NewCustomButton>
          </>
        )}
      </Left>
      <Right>{rightData}</Right>
    </Container>
  )
}

export default SectionMainFeature
