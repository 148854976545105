import networking_icon from "src/images/icons/networking_icon.svg"
import qa_icon from "src/images/icons/q&a_icon.svg"
import passion_project_icon from "src/images/icons/passion_project_icon.svg"

const engageSubFeaturesData = [
  {
    icon: networking_icon,
    header: "Networking",
    body: "Social events including online & offline meet & greets.",
  },
  {
    icon: qa_icon,
    header: "Q&A",
    body: "Ask questions on our Discord server & office hours.",
  },
  {
    icon: passion_project_icon,
    header: "Passion Projects",
    body: "Find collaborators with complementary skills for side projects.",
  },
]

export default engageSubFeaturesData
