import React from "react"
import styled from "styled-components"
import { rem } from "src/utils/styling"

const Container = styled.div`
  width: ${rem(300)}rem;
  margin: 0 ${rem(40)}rem;
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 40px;
  }
  @media screen and (max-width: 1100px) {
    margin: 0;
  }
`

const Icon = styled.img`
  margin-bottom: ${rem(10)}rem;
`

const Header = styled.h5`
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: ${rem(18)}rem;
  line-height: ${rem(34)}rem;
  color: #000000;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`

const Body = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${rem(16)}rem;
  line-height: ${rem(32)}rem;
  color: #000000;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`

const SectionSubFeaturesWithoutCard = ({ icon, header, body }) => {
  return (
    <Container>
      <Icon src={icon} />
      <Header>{header}</Header>
      <Body>{body}</Body>
    </Container>
  )
}

export default SectionSubFeaturesWithoutCard
