// developers
import georgina from "src/images/georgina.jpeg"
import prasanna from "src/images/prasanna.jpeg"
import bello from "src/images/bello.jpeg"
import arther from "src/images/arther.png"
import practitioner_badge from "src/images/practitioner_badge.svg"

// badges
import coder_badge from "src/images/coder_badge.svg"
import expert_badge from "src/images/expert_badge.svg"
import guru_badge from "src/images/guru_badge.svg"

const developerTestimonialsData = [
  {
    image: arther,
    testimonial:
      "I was looking for remote freelance opportunities during my sabbatical and was introduced to Archimydes through an ex-colleague at ThoughtWorks. Their no-nonsense approach to breaking down tasks, clear communication and clarity in managing remote development teams was a dream to work with.",
    name: "Arther Antony",
    position: "Full-stack Developer, Singapore",
    badge: expert_badge,
  },
  {
    image: bello,
    testimonial:
      "I’m delighted to be part of the Archimydes’ guild of developers. They have a well structured process built on the foundation of extreme communication clarity. I’ve gained a lot of experience working on diverse projects, working alongside experts in the field since I joined the Archimydes Platform.",
    name: "Damilola Bello",
    position: "Full-stack Developer, Lagos",
    badge: practitioner_badge,
  },
  {
    image: georgina,
    testimonial:
      "With a diverse portfolio of projects and their remote first way of working, I could choose not just which project they work on but also on which feature, this kept things fresh and interesting.\n",
    name: "Georgina Hoagland",
    position: "Full-stack Developer, NYC",
    badge: practitioner_badge,
  },
  {
    image: prasanna,
    testimonial:
      "Exploring the latest technologies excites me. Archimydes has got a strong  community that shares this same interest as mine. We do a fair bit of experimentation and build solutions on various tech together without ever being co-located.",
    name: "Prasanna Venkatesan",
    position: "Full-stack Developer at ThoughtWorks, Bangalore",
    badge: guru_badge,
  },
]

export default developerTestimonialsData
