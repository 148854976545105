import React, { useReducer } from "react"
import TableColumn from "src/TableColumn"
import { earnTableColumns, tableRowText } from "src/data/earnSubFeaturesData"
import styled from "styled-components"
import { rem } from "src/utils/styling"

const Container = styled.div`
  display: flex;
  margin-bottom: ${rem(40)}rem;
  @media screen and (max-width: 500px) {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
`

function reducer(state, action) {
  switch (action.type) {
    case "ADD_ROW_ITEM":
      return { ...state, selectedItem: action.payload }
    case "REMOVE_ROW_ITEM":
      return { ...state, selectedItem: null }
    default:
      return state
  }
}

const DevBandingTable = () => {
  const [state, dispatch] = useReducer(reducer, {
    selectedItem: null,
  })

  return (
    <Container>
      <TableColumn tableRowText={tableRowText} state={state} />
      {Object.values(earnTableColumns).map(el => (
        <TableColumn
          total={el.total}
          key={el.title}
          badge={el.badge}
          title={el.title}
          checkmark={el.checkmark}
          checkmarkNumber={el.checkmarkNumber}
          dispatch={dispatch}
          state={state}
        />
      ))}
    </Container>
  )
}

export default DevBandingTable
