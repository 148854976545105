import React from "react"
import SectionHeaderWithBadge from "src/components/SectionHeaderWithBadge"
import styled from "styled-components"
import SectionMainFeature from "src/components/SectionMainFeature"
import SectionSubFeaturesWithoutCard from "src/components/SectionSubFeaturesWithoutCard"
import engageSubFeaturesData from "src/data/engageSubFeaturesData"
import earn_end_graphic from "src/images/earn_end_graphic.png"
import discord from "src/images/discord.png"
import { rem } from "src/utils/styling"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${rem(60)}rem 0 ${rem(80)}rem 0;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const SubFeatures = styled.div`
  display: flex;
  margin: ${rem(60)}rem 0 ${rem(80)}rem 0;
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const Image = styled.img`
  height: 400px;
  @media screen and (max-width: 500px) {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
    height: auto;
  }
`

const EngageSection = () => {
  return (
    <Container>
      <SectionHeaderWithBadge
        backgroundColor="#e1e3f7"
        color="#764ba2"
        body="Belong to a community focused on helping you achieve your potential."
        heading="A Close-knit community"
        badgeText="Engage"
      />
      <SectionMainFeature
        rightData={<Image src={discord} alt="" />}
        headline={
          <>
            For Developers. <br />
            By Developers.
          </>
        }
        body={
          <>
            Got questions on software development? Join Our Discord server and
            ask the community. Looking to start your next passion project and
            need collaborators? Create your passion project on our web platform
            and be discovered by the community.
          </>
        }
        ctaData={{
          color: "#764BA2",
          bgColor: "#E1E3F7",
          onClickUrl: "https://discord.gg/zDdDSEbWgh",
          buttonText: "Join Discord",
        }}
      />
      <SubFeatures>
        {engageSubFeaturesData.map(el => (
          <SectionSubFeaturesWithoutCard
            key={el.header}
            body={el.body}
            header={el.header}
            icon={el.icon}
          />
        ))}
      </SubFeatures>
      <img src={earn_end_graphic} alt="" />
    </Container>
  )
}

export default EngageSection
