import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 10px 10px 0 #d5f0f1;
  border-radius: 8px;
  margin: 0 120px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    width: fit-content;
    margin: 0;
    box-shadow: none;
  }
`

const DeveloperImage = styled.img`
  height: 230px;
  border-radius: 8px;
  @media screen and (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`

const DeveloperTestimonialText = styled.h4`
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 15px;
  &:before {
    content: "\\201C";
    color: #00b8c5;
    font-weight: 900;
    font-size: 25px;
  }
  &:after {
    content: "\\201D";
    color: #00b8c5;
    font-weight: 900;
    font-size: 25px;
  }
  @media screen and (max-width: 500px) {
    text-align: center;
    margin-bottom: 20px;
  }
`

const DeveloperName = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`

const DeveloperPosition = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  @media screen and (max-width: 500px) {
    align-items: center;
  }
`

const DevBadge = styled.img`
  height: 70px;
`

const DeveloperDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const DeveloperDetails = styled.div`
  margin-left: 10px;
`

const DeveloperTestimonial = ({
  image,
  testimonial,
  name,
  position,
  badge,
}) => {
  return (
    <Container>
      <DeveloperImage src={image} />
      <TextContainer>
        <DeveloperTestimonialText>{testimonial}</DeveloperTestimonialText>
        <DeveloperDetailsContainer>
          <DevBadge src={badge} alt="badge" />
          <DeveloperDetails>
            <DeveloperName>{name}</DeveloperName>
            <DeveloperPosition>{position}</DeveloperPosition>
          </DeveloperDetails>
        </DeveloperDetailsContainer>
      </TextContainer>
    </Container>
  )
}

export default DeveloperTestimonial
