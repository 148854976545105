import React from "react"
import Button from "src/components/Button"
import styled from "styled-components"
import { HiChevronRight } from "react-icons/hi"

const CustomButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor && props.bgColor};
  color: ${props => props.color && props.color};
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const NewCustomButton = ({ children, color, bgColor, onClickUrl }) => {
  return (
    <a href={onClickUrl} target="_blank">
      <CustomButton bgColor={bgColor} color={color}>
        {children} <HiChevronRight style={{ color }} size={22} />
      </CustomButton>
    </a>
  )
}

export default NewCustomButton
