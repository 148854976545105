import apprentice_badge from "src/images/apprentice_badge.svg"
import practitioner_badge from "src/images/practitioner_badge.svg"
import coder_badge from "src/images/coder_badge.svg"
import expert_badge from "src/images/expert_badge.svg"
import guru_badge from "src/images/guru_badge.svg"
import leader_badge from "src/images/leader_badge.svg"

import apprentice_checkmark from "src/images/icons/apprentice_checkmark.svg"
import practitioner_checkmark from "src/images/icons/practitioner_checkmark.svg"
import coder_checkmark from "src/images/icons/coder_checkmark.svg"
import expert_checkmark from "src/images/icons/expert_checkmark.svg"
import guru_checkmark from "src/images/icons/guru_checkmark.svg"

export const earnTableTitles = [
  "Code as a Service",
  "Full time roles",
  "Referral program",
  "Coach",
  "Interim roles",
  "Micro-consulting",
  "Advisory",
]

export const earnTableColumns = {
  apprentice: {
    badge: apprentice_badge,
    title: "Apprentice",
    checkmark: apprentice_checkmark,
    checkmarkNumber: 2,
    total: 7,
  },
  practitioner: {
    badge: practitioner_badge,
    title: "Practitioner",
    checkmark: practitioner_checkmark,
    checkmarkNumber: 3,
    total: 7,
  },
  expert: {
    badge: expert_badge,
    title: "Expert",
    checkmark: expert_checkmark,
    checkmarkNumber: 4,
    total: 7,
  },
  guru: {
    badge: guru_badge,
    title: "Guru",
    checkmark: guru_checkmark,
    checkmarkNumber: 6,
    total: 7,
  },
  leader: {
    badge: leader_badge,
    title: "Leader",
    checkmark: coder_checkmark,
    checkmarkNumber: 6,
    total: 7,
  },
}

export const tableItemColors = {
  apprentice: {
    background: "#FFF6D7",
    shadow: "#F4EAC8",
  },
  practitioner: {
    background: "#DCF7E8",
    shadow: "#BBE6CE",
  },
  expert: {
    background: "#FFEBEE",
    shadow: "#F1C7CD",
  },
  guru: {
    background: "#EDE7F6",
    shadow: "#D5C4ED",
  },
  leader: {
    background: "#E1F5FE",
    shadow: "#B7DEF0",
  },
}

export const tableRowText = [
  {
    title: "Code as a Service",
    body: "Earn through our unique <br/> task-based delivery system.",
  },
  {
    title: "Full time roles",
    body:
      "Gain access to some of the <br/>  best full-time jobs on the planet.",
  },
  {
    title: "Referral program",
    body:
      "Participate in our revenue sharing <br/>  plan when you refer customers.",
  },
  {
    title: "Coach",
    body:
      "Access coaching opportunities <br/>  both within and outside the guild.",
  },
  {
    title: "Interim roles",
    body:
      "Interim and fractional engagements <br/>  with Archimydes customers.",
  },
  {
    title: "Micro-consulting",
    body: "Monetize your deep domain <br/>  knowledge and expertise.",
  },
  {
    title: "Advisory",
    body: "Monetize your deep domain <br/>  knowledge and expertise.",
  },
]
