import React from "react"
import DeveloperTestimonial from "src/components/DeveloperTestimonial"
import styled from "styled-components"
import { DEVICE_TYPE_DESKTOP, useDeviceType } from "src/utils/deviceType"
import Slider from "react-slick"
import { settings } from "src/components/Slider"
import { MobileOnlyCarousel } from "src/components/Carousel"
import developerTestimonialsData from "src/data/developerTestimonialsData"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContainerInner = styled.div`
  width: 1000px;
  margin: 50px 0 100px 0;
  @media screen and (min-width: 800px) {
    width: 80%;
    margin: 50px 200px 100px 200px;
  }
  @media screen and (min-width: 1100px) {
    width: 80%;
    margin: 50px 200px 100px 200px;
  }
  @media screen and (max-width: 550px) {
    margin: 10px 0;
    width: 100%;
    padding: 0 80px;
  }
`

const Headline = styled.h4`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 38px;
  line-height: 49px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
`

const DeveloperTestimonials = () => {
  const deviceType = useDeviceType()

  return (
    <Container>
      <Headline>What our developers are saying</Headline>
      <ContainerInner>
        {deviceType === DEVICE_TYPE_DESKTOP ? (
          <Slider {...settings}>
            {developerTestimonialsData.map(el => (
              <DeveloperTestimonial
                key={el.name}
                image={el.image}
                name={el.name}
                position={el.position}
                testimonial={el.testimonial}
                badge={el.badge}
              />
            ))}
          </Slider>
        ) : (
          <MobileOnlyCarousel>
            {developerTestimonialsData.map(el => (
              <DeveloperTestimonial
                key={el.name}
                image={el.image}
                name={el.name}
                position={el.position}
                testimonial={el.testimonial}
                badge={el.badge}
              />
            ))}
          </MobileOnlyCarousel>
        )}
      </ContainerInner>
    </Container>
  )
}

export default DeveloperTestimonials
