import mentorship_icon from "src/images/icons/mentorship_icon.svg"
import training_sessions from "src/images/icons/training_sessions.svg"
import code_review from "src/images/icons/code_review.svg"

const growSubFeaturesData = [
  {
    icon: mentorship_icon,
    header: "1:1 Mentorship",
    body: "Develop a growth plan and schedule pair programming sessions with your mentor.",
  },
  {
    icon: code_review,
    header: "Code Review",
    body: "Pull requests on a project are reviewed by a Sr. Developer and feedback is provided.",
  },
  {
    icon: training_sessions,
    header: "Training Sessions",
    body: "Attend our weekly, monthly, quarterly, or annual scheduled training events.",
  },
]

export default growSubFeaturesData
