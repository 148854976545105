import React from "react"
import SectionHeaderWithBadge from "src/components/SectionHeaderWithBadge"
import styled from "styled-components"
import { rem } from "src/utils/styling"
import DevBandingTable from "src/components/DevBandingTable"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${rem(60)}rem 0 ${rem(80)}rem 0;
  @media screen and (max-width: 500px) {
    margin: 0;
  }
`

const Image = styled.img`
  height: 600px;
  @media screen and (max-width: 1100px) {
    width: 100%;
    height: auto;
  }
`

const EarnSection = () => {
  return (
    <Container>
      <SectionHeaderWithBadge
        backgroundColor="#C8EAFE"
        color="#009EFD"
        body="Move beyond simply relying on full-time employment for your income."
        heading="Create Revenue Streams"
        badgeText="earn"
      />
      <DevBandingTable />
    </Container>
  )
}

export default EarnSection
