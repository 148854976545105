import React from "react"
import styled from "styled-components"
import { rem } from "src/utils/styling"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${rem(300)}rem;
  margin: 0 ${rem(40)}rem;
  background: #ffffff;
  border: ${rem(1)}rem solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0 0 ${rem(20)}rem rgba(0, 0, 0, 0.05);
  border-radius: ${rem(8)}rem;
  padding: ${rem(30)}rem ${rem(50)}rem;
  @media screen and (max-width: 1100px) {
    padding: ${rem(30)}rem ${rem(20)}rem;
    margin: ${rem(15)}rem ${rem(10)}rem;
  }
  @media screen and (max-width: 500px) {
    margin: 10px;
  }
`

const Icon = styled.img`
  margin-bottom: ${rem(10)}rem;
`

const Header = styled.h5`
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: ${rem(18)}rem;
  line-height: ${rem(34)}rem;
  color: #000000;
  text-align: center;
`

const Body = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${rem(16)}rem;
  line-height: ${rem(32)}rem;
  color: #000000;
  text-align: center;
`

const SectionSubFeaturesWithCard = ({ icon, header, body }) => {
  return (
    <Container>
      <Icon src={icon} />
      <Header>{header}</Header>
      <Body>{body}</Body>
    </Container>
  )
}

export default SectionSubFeaturesWithCard
