import apprentice_badge from "src/images/apprentice_badge.svg"
import practitioner_badge from "src/images/practitioner_badge.svg"
import coder_badge from "src/images/coder_badge.svg"
import expert_badge from "src/images/expert_badge.svg"
import guru_badge from "src/images/guru_badge.svg"
import leader_badge from "src/images/leader_badge.svg"

export const devBandingDataColumnOne = [
  {
    title: "Apprentice",
    body:
      "High potential developers, self taught or trained, with little to no experience.",
    badge: apprentice_badge,
    backgroundColor: "#FFF6D7",
    borderColor: "#F4EAC8",
  },
  {
    title: "Practitioner",
    body:
      "Developers that have started to realize their potential, but need guidance and challenge.",
    badge: practitioner_badge,
    backgroundColor: "#DCF7E8",
    borderColor: "#BBE5CE",
  },
  {
    title: "Expert",
    body:
      "Experienced developers that can serve as team leads and solution architects.",
    badge: expert_badge,
    backgroundColor: "#FFEBEE",
    borderColor: "#F1C7CD",
  },
]

export const devBandingDataColumnTwo = [
  {
    title: "Guru",
    body:
      "Masters of their craft and highly skilled across multiple software development domains.",
    badge: guru_badge,
    backgroundColor: "#EDE7F6",
    borderColor: "#D5C4ED",
  },
  {
    title: "Leader",
    body:
      "Visionary leaders blending technical expertise and strategy to elevate tech teams.",
    badge: leader_badge,
    backgroundColor: "#E5F4FD",
    borderColor: "#BFDDEE",
  },
]
