import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { rem } from "src/utils/styling"
import ReactTooltip from "react-tooltip"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${rem(190)}rem;
  padding: ${rem(20)}rem ${rem(0)}rem;
  margin: 0 ${rem(0)}rem;
  transition: 0.3s;
  border-radius: ${rem(8)}rem;
  background-color: white;
  @media screen and (max-width: 1100px) {
    width: ${rem(140)}rem;
  }
  ${props =>
    props.isDevBand &&
    css`
      @media screen and (max-width: 500px) {
        min-width: ${rem(100)}rem;
      }
    `}
  ${props =>
    !props.isDevBand &&
    css`
      @media screen and (max-width: 500px) {
        position: sticky;
        left: 0;
        width: ${rem(180)}rem;
        box-shadow: 10px 14px 5px -9px rgba(0, 0, 0, 0.19);
        border-radius: 0;
      }
    `}
`

const Badge = styled.img`
  margin-bottom: ${rem(15)}rem;
  height: ${rem(70)}rem;
  @media screen and (max-width: 500px) {
    height: ${rem(50)}rem;
  }
`

const Title = styled.h5`
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: ${rem(20)}rem;
  line-height: ${rem(34)}rem;
  color: #000000;
  margin-bottom: ${rem(15)}rem;
  @media screen and (max-width: 500px) {
    font-size: ${rem(14)}rem;
  }
`

const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: ${rem(180)}rem;
  @media screen and (max-width: 500px) {
    min-height: ${rem(120)}rem;
  }
`

const Checkmark = styled.img``

const CheckmarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  height: ${rem(65)}rem;
  background-color: white;

  ${props =>
    props.idx &&
    props.idx % 2 !== 0 &&
    css`
      background-color: #fafafa;
    `}

  @media screen and (max-width: 500px) {
    height: ${rem(50)}rem;
  }
`

const TableRowTitle = styled.p`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${rem(17)}rem;
  line-height: normal;
  color: #4a4a4a;
  text-align: left;
  width: 100%;
  transition: 0.1s;
  margin-bottom: ${rem(5)}rem;
  text-decoration: underline;
  text-decoration-style: under;
  cursor: help;
  margin-left: ${rem(50)}rem;
  @media screen and (max-width: 1100px) {
    font-size: ${rem(14)}rem;
  }
  &:hover {
    color: #00b8c5;
    font-weight: 800;
  }
  ${props =>
    props.highlight &&
    css`
      color: #00b8c5;
      font-weight: 800;
    `}
`

const TableColumn = ({
  badge,
  title,
  checkmark,
  checkmarkNumber,
  tableRowText,
  dispatch,
  state,
  total,
}) => {
  const [count, setCount] = useState([0])

  useEffect(() => {
    for (let i = 0; i < total - 1; i++) {
      setCount(prev => [...prev, i])
    }
  }, [])

  const setRowItem = index => {
    dispatch({ type: "ADD_ROW_ITEM", payload: index })
  }

  const removeRowItem = () => {
    dispatch({ type: "REMOVE_ROW_ITEM" })
  }

  return (
    <Container isDevBand={!!badge}>
      <ContainerHeader>
        {badge && title ? (
          <>
            <Badge src={badge} alt="badge" />
            <Title>{title}</Title>
          </>
        ) : (
          <div />
        )}
      </ContainerHeader>
      {checkmarkNumber && checkmark ? (
        <>
          {count.map((_, idx) => {
            return idx <= checkmarkNumber ? (
              <CheckmarkContainer
                key={idx}
                idx={idx}
                onMouseEnter={() => setRowItem(idx)}
                onMouseLeave={removeRowItem}
              >
                <Checkmark src={checkmark} alt="checkmark" />
              </CheckmarkContainer>
            ) : (
              <CheckmarkContainer
                idx={idx}
                key={idx}
                onMouseEnter={() => setRowItem(idx)}
                onMouseLeave={removeRowItem}
              >
                <div />
              </CheckmarkContainer>
            )
          })}
        </>
      ) : (
        <>
          {tableRowText.map((text, idx) => (
            <CheckmarkContainer key={idx} idx={idx}>
              <TableRowTitle
                data-tip={text.body}
                highlight={state.selectedItem === idx}
              >
                {text.title}
              </TableRowTitle>
            </CheckmarkContainer>
          ))}
        </>
      )}
      <ReactTooltip
        place="right"
        backgroundColor="#000000"
        style={{ width: "100px" }}
        textColor="#ffffff"
        multiline
      />
    </Container>
  )
}

export default TableColumn
